import React, { useState } from "react"
import Lottie from "react-lottie"
import animationData from "./tideIconHigh.json"

const TideIconsLottie = () => {
  const [isStopped, setIsStopped] = useState(true)
  const [isPaused, setIsPaused] = useState(false)
  const [showPlay, setShowPlay] = useState(true)
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    renderer: "svg",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const eventListeners = [
    {
      eventName: "complete",
      callback: () => setShowPlay(false),
    },
  ]

  const buttonStyle = {
    display: "block",
    margin: "10px auto",
  }
  return (
    <div>
      <Lottie
        options={defaultOptions}
        eventListeners={eventListeners}
        height={300}
        width={300}
        isStopped={isStopped}
        isPaused={isPaused}
      />
      {!showPlay ? (
        <button
          style={buttonStyle}
          onClick={() => {
            setIsStopped(true)
            setShowPlay(true)
          }}
        >
          reset
        </button>
      ) : (
        <button style={buttonStyle} onClick={() => setIsStopped(false)}>
          play
        </button>
      )}
      <button style={buttonStyle} onClick={() => setIsPaused(!isPaused)}>
        {!isPaused ? "pause" : "un pause"}
      </button>
    </div>
  )
}

export default TideIconsLottie
